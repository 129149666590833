import { useEffect, useState } from 'react';

export const getWorldPopulation = () => {
  const startingDate = new Date('11/15/2022');
  const actualDay = new Date();
  const differenceTime = Math.abs(actualDay.getTime() - startingDate.getTime());
  const differenceSeconds = Math.ceil(differenceTime / 1000);

  const populationOnStartingDate = 8000000000;

  const everySecondPeople = 2.3;

  const [totalPopulation, setTotalPopulation] = useState(
    populationOnStartingDate + everySecondPeople * differenceSeconds
  );

  useEffect(() => {
    const intervalTime = 1000 / everySecondPeople;

    const addHumansInterval = setInterval(() => {
      setTotalPopulation((prevState) => prevState + 1);
    }, intervalTime);

    return () => clearInterval(addHumansInterval);
  }, []);

  return Math.round(totalPopulation);
};

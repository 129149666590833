/**
 * Converts special characters to url friendly ones
 * @param string
 * @returns {*}
 */
export const convertToUrl = (string: string) => {
  return (
    (string || '')
      .toString() // Convert to string
      .normalize('NFD') // Change diacritics
      .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
      .replace(/\s+/g, '-') // Change whitespace to dashes
      .toLowerCase() // Change to lowercase
      .replace(/&/g, '-and-') // Replace ampersand
      // eslint-disable-next-line no-useless-escape
      .replace(/[^a-z\d\-]/g, '') // Remove anything that is not a letter, number or dash
      .replace(/-+/g, '-') // Remove duplicate dashes
      .replace(/^-*/, '') // Remove starting dashes
      .replace(/-*$/, '')
  );
};

import * as React from 'react';

function InfoOutlinedIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M10.625 13.75v-5h-2.5V10h1.25v3.75H7.5V15h5v-1.25h-1.875zM10 5a.937.937 0 100 1.875A.937.937 0 0010 5z"
        fill="#000"
      />
      <path
        d="M10 18.75a8.75 8.75 0 110-17.5 8.75 8.75 0 010 17.5zM10 2.5a7.5 7.5 0 100 15 7.5 7.5 0 000-15z"
        fill="#000"
      />
    </svg>
  );
}

export default InfoOutlinedIcon;

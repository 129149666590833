/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
export const checkForCountriesWithCountryParent = (
  newSiblings: any[],
  name: string
) => {
  const allPathsAndValues: {
    name: any;
    value: any;
    fillColor: any;
    strokeColor: any;
  }[] = [];

  const namesForAU = [
    'F#feature#1#0#AU#0',
    'F#feature#1#0#CX#0',
    'F#feature#1#0#CC#0',
    'F#feature#1#0#HM#0',
    'F#feature#1#0#NF#0',
  ];

  if (namesForAU.includes(name)) {
    namesForAU.forEach((nextName: string) => {
      const index = newSiblings.findIndex(
        // @ts-ignore
        (child) => child.logicalname === nextName
      );
      const nextSiblings = newSiblings.slice(index + 1);

      newSiblings.forEach((sibling) => {
        // @ts-ignore
        if (sibling.logicalname === nextName) {
          allPathsAndValues.push({
            // @ts-ignore
            name: sibling.logicalname,
            // @ts-ignore
            value: sibling.attributes.getNamedItem('d').value,
            // @ts-ignore
            fillColor: sibling.attributes.getNamedItem('fill').value,
            // @ts-ignore
            strokeColor: sibling.attributes.getNamedItem('stroke').value,
          });
        }
      });

      nextSiblings.forEach((nextSibling) => {
        // @ts-ignore
        if (nextSibling.logicalname === nextName) {
          allPathsAndValues.push({
            // @ts-ignore
            name: nextSibling.logicalname,
            // @ts-ignore
            value: nextSibling.attributes.getNamedItem('d').value,
            // @ts-ignore
            fillColor: nextSibling.attributes.getNamedItem('fill').value,
            strokeColor:
              // @ts-ignore
              nextSibling.attributes.getNamedItem('stroke').value,
          });
        }
      });
    });
  }

  const namesForCN = [
    'F#feature#1#0#HK#0',
    'F#feature#1#0#MO#0',
    'F#feature#1#0#CN#0',
  ];

  if (namesForCN.includes(name)) {
    namesForCN.forEach((nextName: string) => {
      const index = newSiblings.findIndex(
        // @ts-ignore
        (child) => child.logicalname === nextName
      );
      const nextSiblings = newSiblings.slice(index + 1);

      newSiblings.forEach((sibling) => {
        // @ts-ignore
        if (sibling.logicalname === nextName) {
          allPathsAndValues.push({
            // @ts-ignore
            name: sibling.logicalname,
            // @ts-ignore
            value: sibling.attributes.getNamedItem('d').value,
            // @ts-ignore
            fillColor: sibling.attributes.getNamedItem('fill').value,
            // @ts-ignore
            strokeColor: sibling.attributes.getNamedItem('stroke').value,
          });
        }
      });

      nextSiblings.forEach((nextSibling) => {
        // @ts-ignore
        if (nextSibling.logicalname === nextName) {
          allPathsAndValues.push({
            // @ts-ignore
            name: nextSibling.logicalname,
            // @ts-ignore
            value: nextSibling.attributes.getNamedItem('d').value,
            // @ts-ignore
            fillColor: nextSibling.attributes.getNamedItem('fill').value,
            strokeColor:
              // @ts-ignore
              nextSibling.attributes.getNamedItem('stroke').value,
          });
        }
      });
    });
  }

  const namesForDK = [
    'F#feature#1#0#FO#0',
    'F#feature#1#0#GL#0',
    'F#feature#1#0#DK#0',
  ];

  if (namesForDK.includes(name)) {
    namesForDK.forEach((nextName: string) => {
      const index = newSiblings.findIndex(
        // @ts-ignore
        (child) => child.logicalname === nextName
      );
      const nextSiblings = newSiblings.slice(index + 1);

      newSiblings.forEach((sibling) => {
        // @ts-ignore
        if (sibling.logicalname === nextName) {
          allPathsAndValues.push({
            // @ts-ignore
            name: sibling.logicalname,
            // @ts-ignore
            value: sibling.attributes.getNamedItem('d').value,
            // @ts-ignore
            fillColor: sibling.attributes.getNamedItem('fill').value,
            // @ts-ignore
            strokeColor: sibling.attributes.getNamedItem('stroke').value,
          });
        }
      });

      nextSiblings.forEach((nextSibling) => {
        // @ts-ignore
        if (nextSibling.logicalname === nextName) {
          allPathsAndValues.push({
            // @ts-ignore
            name: nextSibling.logicalname,
            // @ts-ignore
            value: nextSibling.attributes.getNamedItem('d').value,
            // @ts-ignore
            fillColor: nextSibling.attributes.getNamedItem('fill').value,
            strokeColor:
              // @ts-ignore
              nextSibling.attributes.getNamedItem('stroke').value,
          });
        }
      });
    });
  }

  const namesForFR = [
    'F#feature#1#0#FR#0',
    'F#feature#1#0#PF#0',
    'F#feature#1#0#NC#0',
    'F#feature#1#0#BL#0',
    'F#feature#1#0#MF#0',
    'F#feature#1#0#PM#0',
    'F#feature#1#0#RE#0',
    'F#feature#1#0#WF#0',
  ];

  if (namesForFR.includes(name)) {
    namesForFR.forEach((nextName: string) => {
      const index = newSiblings.findIndex(
        // @ts-ignore
        (child) => child.logicalname === nextName
      );
      const nextSiblings = newSiblings.slice(index + 1);

      newSiblings.forEach((sibling) => {
        // @ts-ignore
        if (sibling.logicalname === nextName) {
          allPathsAndValues.push({
            // @ts-ignore
            name: sibling.logicalname,
            // @ts-ignore
            value: sibling.attributes.getNamedItem('d').value,
            // @ts-ignore
            fillColor: sibling.attributes.getNamedItem('fill').value,
            // @ts-ignore
            strokeColor: sibling.attributes.getNamedItem('stroke').value,
          });
        }
      });

      nextSiblings.forEach((nextSibling) => {
        // @ts-ignore
        if (nextSibling.logicalname === nextName) {
          allPathsAndValues.push({
            // @ts-ignore
            name: nextSibling.logicalname,
            // @ts-ignore
            value: nextSibling.attributes.getNamedItem('d').value,
            // @ts-ignore
            fillColor: nextSibling.attributes.getNamedItem('fill').value,
            strokeColor:
              // @ts-ignore
              nextSibling.attributes.getNamedItem('stroke').value,
          });
        }
      });
    });
  }

  const namesforNL = [
    'F#feature#1#0#NL#0',
    'F#feature#1#0#AW#0',
    'F#feature#1#0#CW#0',
    'F#feature#1#0#SX#0',
  ];

  if (namesforNL.includes(name)) {
    namesforNL.forEach((nextName: string) => {
      const index = newSiblings.findIndex(
        // @ts-ignore
        (child) => child.logicalname === nextName
      );
      const nextSiblings = newSiblings.slice(index + 1);

      newSiblings.forEach((sibling) => {
        // @ts-ignore
        if (sibling.logicalname === nextName) {
          allPathsAndValues.push({
            // @ts-ignore
            name: sibling.logicalname,
            // @ts-ignore
            value: sibling.attributes.getNamedItem('d').value,
            // @ts-ignore
            fillColor: sibling.attributes.getNamedItem('fill').value,
            // @ts-ignore
            strokeColor: sibling.attributes.getNamedItem('stroke').value,
          });
        }
      });

      nextSiblings.forEach((nextSibling) => {
        // @ts-ignore
        if (nextSibling.logicalname === nextName) {
          allPathsAndValues.push({
            // @ts-ignore
            name: nextSibling.logicalname,
            // @ts-ignore
            value: nextSibling.attributes.getNamedItem('d').value,
            // @ts-ignore
            fillColor: nextSibling.attributes.getNamedItem('fill').value,
            strokeColor:
              // @ts-ignore
              nextSibling.attributes.getNamedItem('stroke').value,
          });
        }
      });
    });
  }

  const namesForNZ = ['F#feature#1#0#NZ#0', 'F#feature#1#0#TK#0'];

  if (namesForNZ.includes(name)) {
    namesForNZ.forEach((nextName: string) => {
      const index = newSiblings.findIndex(
        // @ts-ignore
        (child) => child.logicalname === nextName
      );
      const nextSiblings = newSiblings.slice(index + 1);

      newSiblings.forEach((sibling) => {
        // @ts-ignore
        if (sibling.logicalname === nextName) {
          allPathsAndValues.push({
            // @ts-ignore
            name: sibling.logicalname,
            // @ts-ignore
            value: sibling.attributes.getNamedItem('d').value,
            // @ts-ignore
            fillColor: sibling.attributes.getNamedItem('fill').value,
            // @ts-ignore
            strokeColor: sibling.attributes.getNamedItem('stroke').value,
          });
        }
      });

      nextSiblings.forEach((nextSibling) => {
        // @ts-ignore
        if (nextSibling.logicalname === nextName) {
          allPathsAndValues.push({
            // @ts-ignore
            name: nextSibling.logicalname,
            // @ts-ignore
            value: nextSibling.attributes.getNamedItem('d').value,
            // @ts-ignore
            fillColor: nextSibling.attributes.getNamedItem('fill').value,
            strokeColor:
              // @ts-ignore
              nextSibling.attributes.getNamedItem('stroke').value,
          });
        }
      });
    });
  }

  const namesForUK = [
    'F#feature#1#0#GB#0',
    'F#feature#1#0#AI#0',
    'F#feature#1#0#IO#0',
    'F#feature#1#0#VG#0',
    'F#feature#1#0#KY#0',
    'F#feature#1#0#FK#0',
    'F#feature#1#0#GI#0',
    'F#feature#1#0#MS#0',
    'F#feature#1#0#PC#0',
    'F#feature#1#0#SH#0',
    'F#feature#1#0#GS#0',
    'F#feature#1#0#TC#0',
    'F#feature#1#0#GG#0',
    'F#feature#1#0#IM#0',
    'F#feature#1#0#JU#0',
  ];

  if (namesForUK.includes(name)) {
    namesForUK.forEach((nextName: string) => {
      const index = newSiblings.findIndex(
        // @ts-ignore
        (child) => child.logicalname === nextName
      );
      const nextSiblings = newSiblings.slice(index + 1);

      newSiblings.forEach((sibling) => {
        // @ts-ignore
        if (sibling.logicalname === nextName) {
          allPathsAndValues.push({
            // @ts-ignore
            name: sibling.logicalname,
            // @ts-ignore
            value: sibling.attributes.getNamedItem('d').value,
            // @ts-ignore
            fillColor: sibling.attributes.getNamedItem('fill').value,
            // @ts-ignore
            strokeColor: sibling.attributes.getNamedItem('stroke').value,
          });
        }
      });

      nextSiblings.forEach((nextSibling) => {
        // @ts-ignore
        if (nextSibling.logicalname === nextName) {
          allPathsAndValues.push({
            // @ts-ignore
            name: nextSibling.logicalname,
            // @ts-ignore
            value: nextSibling.attributes.getNamedItem('d').value,
            // @ts-ignore
            fillColor: nextSibling.attributes.getNamedItem('fill').value,
            strokeColor:
              // @ts-ignore
              nextSibling.attributes.getNamedItem('stroke').value,
          });
        }
      });
    });
  }

  const namesForUS = [
    'F#feature#1#0#AS#0',
    'F#feature#1#0#GU#0',
    'F#feature#1#0#MP#0',
    'F#feature#1#0#PR#0',
    'F#feature#1#0#VI#0',
  ];

  if (namesForUS.includes(name)) {
    namesForUS.forEach((nextName: string) => {
      const index = newSiblings.findIndex(
        // @ts-ignore
        (child) => child.logicalname === nextName
      );
      const nextSiblings = newSiblings.slice(index + 1);

      newSiblings.forEach((sibling) => {
        // @ts-ignore
        if (sibling.logicalname === nextName) {
          allPathsAndValues.push({
            // @ts-ignore
            name: sibling.logicalname,
            // @ts-ignore
            value: sibling.attributes.getNamedItem('d').value,
            // @ts-ignore
            fillColor: sibling.attributes.getNamedItem('fill').value,
            // @ts-ignore
            strokeColor: sibling.attributes.getNamedItem('stroke').value,
          });
        }
      });

      nextSiblings.forEach((nextSibling) => {
        // @ts-ignore
        if (nextSibling.logicalname === nextName) {
          allPathsAndValues.push({
            // @ts-ignore
            name: nextSibling.logicalname,
            // @ts-ignore
            value: nextSibling.attributes.getNamedItem('d').value,
            // @ts-ignore
            fillColor: nextSibling.attributes.getNamedItem('fill').value,
            strokeColor:
              // @ts-ignore
              nextSibling.attributes.getNamedItem('stroke').value,
          });
        }
      });
    });
  }

  return allPathsAndValues;
};

import moment from 'moment';

// Test Function until the API will be updated
export default function getPassedSeconds(day?: string) {
  if (!day) return 0;
  const startDate = moment(day);
  const currentTime = moment.utc();
  const duration = moment.duration(currentTime.diff(startDate));

  return +duration.asSeconds().toFixed();
}

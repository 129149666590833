import isEmpty from 'lodash/isEmpty';
import type { DataWorldChartTypes } from '../../types/types';
import { formatNumber } from '../../utils/format';
import { Label } from '../label/label';

interface iProps {
  totalPopulation: string;
  recordedPopulation?: string;
  countryFromRedux: DataWorldChartTypes | unknown;
  countryData: { population: { living: number; pending: number } };
  recorded: string;
}

const InformationCard = (props: iProps) => {
  const {
    totalPopulation,
    recordedPopulation,
    countryFromRedux,
    countryData,
    recorded = '',
  } = props;

  return (
    <div className="z-[9] mt-[-70px] flex w-full items-center justify-center pb-[20px]">
      <div className="mx-[16px] mt-[18px] flex w-full items-center rounded-[5px] bg-white px-[10px] pb-[13px] pt-[7px] shadow-md">
        <div className="flex w-full flex-col items-center justify-center px-[6px]">
          <Label
            className="small-caps mb-[8px] text-center"
            lineHeight={12}
            fontWeight={400}
            fontSize={10}
          >
            TOTAL POPULATION
          </Label>
          <p className="text-[14px] font-[500] tabular-nums leading-[17px]">
            {!isEmpty(countryFromRedux)
              ? formatNumber(
                  (countryFromRedux as DataWorldChartTypes).population
                ) || 0
              : formatNumber(totalPopulation)}
          </p>
        </div>
        <div className="relative flex w-full flex-col items-center justify-center px-[6px]">
          <div className="absolute left-0 h-[29px] w-px bg-black opacity-10" />
          <Label
            className="small-caps mb-[8px] text-center"
            lineHeight={12}
            fontWeight={400}
            fontSize={10}
          >
            TOTAL RECORDED
          </Label>
          <p className="text-[14px] font-[500] leading-[17px]">
            <span className="tabular-nums">{recordedPopulation}</span>
          </p>
          <div className="absolute right-0 h-[29px] w-px bg-black opacity-10" />
        </div>
        <div className="flex w-full flex-col items-center justify-center px-[6px]">
          <Label
            className="small-caps mb-[8px] text-center"
            lineHeight={12}
            fontWeight={400}
            fontSize={10}
          >
            PERCENTAGE RECORDED
          </Label>
          <p className="text-[14px] font-[500] leading-[17px]">
            {' '}
            {!isEmpty(countryFromRedux)
              ? (countryFromRedux as DataWorldChartTypes).record_count
                ? (
                    (countryData?.population?.living /
                      ((countryFromRedux as DataWorldChartTypes).population +
                        1)) *
                    100
                  ).toFixed(2) + '%'
                : ''
              : parseFloat(
                  recorded === '0.00' || recorded === '0' ? '' : recorded
                ).toFixed(2) + '%'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default InformationCard;

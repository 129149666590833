import type { IconProps } from '../types/icons';

export default function RoundUsers({
  width = 85,
  height = 85,
  fill = 'none',
  className = '',
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 85 85`}
      fill="none"
      className={className}
    >
      <g transform={`scale(${width / 85},${height / 85}`}>
        <mask
          id="mask0_641_10620"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="85"
          height="85"
        >
          <circle cx="42.5" cy="42.5" r="42.5" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_641_10620)">
          <g opacity="0.9" clipPath="url(#clip0_641_10620)">
            <path
              d="M62.9 47.3875C66.5955 47.3875 69.5938 44.3893 69.5938 40.6937C69.5938 36.9982 66.5955 34 62.9 34C59.2045 34 56.2062 36.9982 56.2062 40.6937C56.2062 44.3893 59.2045 47.3875 62.9 47.3875ZM68.85 48.875H66.2887C65.2568 49.3491 64.1086 49.6187 62.9 49.6187C61.6914 49.6187 60.5479 49.3491 59.5113 48.875H56.95C53.6636 48.875 51 51.5386 51 54.825V55.5687C51 56.8006 51.9994 57.8 53.2312 57.8H72.5687C73.8006 57.8 74.8 56.8006 74.8 55.5687V54.825C74.8 51.5386 72.1364 48.875 68.85 48.875Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip1_641_10620)">
            <path
              d="M21.2496 21.8875C24.9451 21.8875 27.9434 18.8893 27.9434 15.1937C27.9434 11.4982 24.9451 8.5 21.2496 8.5C17.5541 8.5 14.5559 11.4982 14.5559 15.1937C14.5559 18.8893 17.5541 21.8875 21.2496 21.8875ZM27.1996 23.375H24.6383C23.6064 23.8491 22.4582 24.1187 21.2496 24.1187C20.041 24.1187 18.8975 23.8491 17.8609 23.375H15.2996C12.0132 23.375 9.34961 26.0386 9.34961 29.325V30.0687C9.34961 31.3006 10.349 32.3 11.5809 32.3H30.9184C32.1502 32.3 33.1496 31.3006 33.1496 30.0687V29.325C33.1496 26.0386 30.4861 23.375 27.1996 23.375Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip2_641_10620)">
            <path
              d="M21.2496 72.8875C24.9451 72.8875 27.9434 69.8893 27.9434 66.1937C27.9434 62.4982 24.9451 59.5 21.2496 59.5C17.5541 59.5 14.5559 62.4982 14.5559 66.1937C14.5559 69.8893 17.5541 72.8875 21.2496 72.8875ZM27.1996 74.375H24.6383C23.6064 74.8491 22.4582 75.1187 21.2496 75.1187C20.041 75.1187 18.8975 74.8491 17.8609 74.375H15.2996C12.0132 74.375 9.34961 77.0386 9.34961 80.325V81.0687C9.34961 82.3006 10.349 83.3 11.5809 83.3H30.9184C32.1502 83.3 33.1496 82.3006 33.1496 81.0687V80.325C33.1496 77.0386 30.4861 74.375 27.1996 74.375Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip3_641_10620)">
            <path
              d="M22.1002 47.3875C25.7957 47.3875 28.7939 44.3893 28.7939 40.6937C28.7939 36.9982 25.7957 34 22.1002 34C18.4047 34 15.4064 36.9982 15.4064 40.6937C15.4064 44.3893 18.4047 47.3875 22.1002 47.3875ZM28.0502 48.875H25.4889C24.457 49.3491 23.3088 49.6187 22.1002 49.6187C20.8916 49.6187 19.7481 49.3491 18.7115 48.875H16.1502C12.8637 48.875 10.2002 51.5386 10.2002 54.825V55.5687C10.2002 56.8006 11.1996 57.8 12.4314 57.8H31.7689C33.0008 57.8 34.0002 56.8006 34.0002 55.5687V54.825C34.0002 51.5386 31.3366 48.875 28.0502 48.875Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip4_641_10620)">
            <path
              d="M62.9 72.8875C66.5955 72.8875 69.5938 69.8893 69.5938 66.1937C69.5938 62.4982 66.5955 59.5 62.9 59.5C59.2045 59.5 56.2062 62.4982 56.2062 66.1937C56.2062 69.8893 59.2045 72.8875 62.9 72.8875ZM68.85 74.375H66.2887C65.2568 74.8491 64.1086 75.1187 62.9 75.1187C61.6914 75.1187 60.5479 74.8491 59.5113 74.375H56.95C53.6636 74.375 51 77.0386 51 80.325V81.0687C51 82.3006 51.9994 83.3 53.2312 83.3H72.5687C73.8006 83.3 74.8 82.3006 74.8 81.0687V80.325C74.8 77.0386 72.1364 74.375 68.85 74.375Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip5_641_10620)">
            <path
              d="M62.9 21.8875C66.5955 21.8875 69.5938 18.8893 69.5938 15.1937C69.5938 11.4982 66.5955 8.5 62.9 8.5C59.2045 8.5 56.2062 11.4982 56.2062 15.1937C56.2062 18.8893 59.2045 21.8875 62.9 21.8875ZM68.85 23.375H66.2887C65.2568 23.8491 64.1086 24.1187 62.9 24.1187C61.6914 24.1187 60.5479 23.8491 59.5113 23.375H56.95C53.6636 23.375 51 26.0386 51 29.325V30.0687C51 31.3006 51.9994 32.3 53.2312 32.3H72.5687C73.8006 32.3 74.8 31.3006 74.8 30.0687V29.325C74.8 26.0386 72.1364 23.375 68.85 23.375Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip6_641_10620)">
            <path
              d="M42.4996 56.7371C46.1951 56.7371 49.1934 53.7389 49.1934 50.0434C49.1934 46.3479 46.1951 43.3496 42.4996 43.3496C38.8041 43.3496 35.8059 46.3479 35.8059 50.0434C35.8059 53.7389 38.8041 56.7371 42.4996 56.7371ZM48.4496 58.2246H45.8883C44.8564 58.6987 43.7082 58.9684 42.4996 58.9684C41.291 58.9684 40.1475 58.6987 39.1109 58.2246H36.5496C33.2632 58.2246 30.5996 60.8882 30.5996 64.1746V64.9184C30.5996 66.1502 31.599 67.1496 32.8309 67.1496H52.1684C53.4002 67.1496 54.3996 66.1502 54.3996 64.9184V64.1746C54.3996 60.8882 51.7361 58.2246 48.4496 58.2246Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip7_641_10620)">
            <path
              d="M0.850195 31.2371C4.5457 31.2371 7.54395 28.2389 7.54395 24.5434C7.54395 20.8479 4.5457 17.8496 0.850195 17.8496C-2.84531 17.8496 -5.84355 20.8479 -5.84355 24.5434C-5.84355 28.2389 -2.84531 31.2371 0.850195 31.2371ZM6.80019 32.7246H4.23891C3.20695 33.1987 2.05879 33.4684 0.850195 33.4684C-0.358398 33.4684 -1.50191 33.1987 -2.53852 32.7246H-5.0998C-8.38625 32.7246 -11.0498 35.3882 -11.0498 38.6746V39.4184C-11.0498 40.6502 -10.0504 41.6496 -8.81855 41.6496H10.5189C11.7508 41.6496 12.7502 40.6502 12.7502 39.4184V38.6746C12.7502 35.3882 10.0866 32.7246 6.80019 32.7246Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip8_641_10620)">
            <path
              d="M0.850195 82.2371C4.5457 82.2371 7.54395 79.2389 7.54395 75.5434C7.54395 71.8479 4.5457 68.8496 0.850195 68.8496C-2.84531 68.8496 -5.84355 71.8479 -5.84355 75.5434C-5.84355 79.2389 -2.84531 82.2371 0.850195 82.2371ZM6.80019 83.7246H4.23891C3.20695 84.1987 2.05879 84.4684 0.850195 84.4684C-0.358398 84.4684 -1.50191 84.1987 -2.53852 83.7246H-5.0998C-8.38625 83.7246 -11.0498 86.3882 -11.0498 89.6746V90.4184C-11.0498 91.6502 -10.0504 92.6496 -8.81855 92.6496H10.5189C11.7508 92.6496 12.7502 91.6502 12.7502 90.4184V89.6746C12.7502 86.3882 10.0866 83.7246 6.80019 83.7246Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip9_641_10620)">
            <path
              d="M83.3004 82.2371C86.9959 82.2371 89.9941 79.2389 89.9941 75.5434C89.9941 71.8479 86.9959 68.8496 83.3004 68.8496C79.6049 68.8496 76.6066 71.8479 76.6066 75.5434C76.6066 79.2389 79.6049 82.2371 83.3004 82.2371ZM89.2504 83.7246H86.6891C85.6571 84.1987 84.509 84.4684 83.3004 84.4684C82.0918 84.4684 80.9483 84.1987 79.9117 83.7246H77.3504C74.0639 83.7246 71.4004 86.3882 71.4004 89.6746V90.4184C71.4004 91.6502 72.3998 92.6496 73.6316 92.6496H92.9691C94.201 92.6496 95.2004 91.6502 95.2004 90.4184V89.6746C95.2004 86.3882 92.5368 83.7246 89.2504 83.7246Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip10_641_10620)">
            <path
              d="M42.4996 82.2371C46.1951 82.2371 49.1934 79.2389 49.1934 75.5434C49.1934 71.8479 46.1951 68.8496 42.4996 68.8496C38.8041 68.8496 35.8059 71.8479 35.8059 75.5434C35.8059 79.2389 38.8041 82.2371 42.4996 82.2371ZM48.4496 83.7246H45.8883C44.8564 84.1987 43.7082 84.4684 42.4996 84.4684C41.291 84.4684 40.1475 84.1987 39.1109 83.7246H36.5496C33.2632 83.7246 30.5996 86.3882 30.5996 89.6746V90.4184C30.5996 91.6502 31.599 92.6496 32.8309 92.6496H52.1684C53.4002 92.6496 54.3996 91.6502 54.3996 90.4184V89.6746C54.3996 86.3882 51.7361 83.7246 48.4496 83.7246Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip11_641_10620)">
            <path
              d="M42.4996 5.73711C46.1951 5.73711 49.1934 2.73887 49.1934 -0.956641C49.1934 -4.65215 46.1951 -7.65039 42.4996 -7.65039C38.8041 -7.65039 35.8059 -4.65215 35.8059 -0.956641C35.8059 2.73887 38.8041 5.73711 42.4996 5.73711ZM48.4496 7.22461H45.8883C44.8564 7.69875 43.7082 7.96836 42.4996 7.96836C41.291 7.96836 40.1475 7.69875 39.1109 7.22461H36.5496C33.2632 7.22461 30.5996 9.88816 30.5996 13.1746V13.9184C30.5996 15.1502 31.599 16.1496 32.8309 16.1496H52.1684C53.4002 16.1496 54.3996 15.1502 54.3996 13.9184V13.1746C54.3996 9.88816 51.7361 7.22461 48.4496 7.22461Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip12_641_10620)">
            <path
              d="M83.3004 31.2371C86.9959 31.2371 89.9941 28.2389 89.9941 24.5434C89.9941 20.8479 86.9959 17.8496 83.3004 17.8496C79.6049 17.8496 76.6066 20.8479 76.6066 24.5434C76.6066 28.2389 79.6049 31.2371 83.3004 31.2371ZM89.2504 32.7246H86.6891C85.6571 33.1987 84.509 33.4684 83.3004 33.4684C82.0918 33.4684 80.9483 33.1987 79.9117 32.7246H77.3504C74.0639 32.7246 71.4004 35.3882 71.4004 38.6746V39.4184C71.4004 40.6502 72.3998 41.6496 73.6316 41.6496H92.9691C94.201 41.6496 95.2004 40.6502 95.2004 39.4184V38.6746C95.2004 35.3882 92.5368 32.7246 89.2504 32.7246Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip13_641_10620)">
            <path
              d="M42.4996 31.2371C46.1951 31.2371 49.1934 28.2389 49.1934 24.5434C49.1934 20.8479 46.1951 17.8496 42.4996 17.8496C38.8041 17.8496 35.8059 20.8479 35.8059 24.5434C35.8059 28.2389 38.8041 31.2371 42.4996 31.2371ZM48.4496 32.7246H45.8883C44.8564 33.1987 43.7082 33.4684 42.4996 33.4684C41.291 33.4684 40.1475 33.1987 39.1109 32.7246H36.5496C33.2632 32.7246 30.5996 35.3882 30.5996 38.6746V39.4184C30.5996 40.6502 31.599 41.6496 32.8309 41.6496H52.1684C53.4002 41.6496 54.3996 40.6502 54.3996 39.4184V38.6746C54.3996 35.3882 51.7361 32.7246 48.4496 32.7246Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip14_641_10620)">
            <path
              d="M0.850195 5.73711C4.5457 5.73711 7.54395 2.73887 7.54395 -0.956641C7.54395 -4.65215 4.5457 -7.65039 0.850195 -7.65039C-2.84531 -7.65039 -5.84355 -4.65215 -5.84355 -0.956641C-5.84355 2.73887 -2.84531 5.73711 0.850195 5.73711ZM6.80019 7.22461H4.23891C3.20695 7.69875 2.05879 7.96836 0.850195 7.96836C-0.358398 7.96836 -1.50191 7.69875 -2.53852 7.22461H-5.0998C-8.38625 7.22461 -11.0498 9.88816 -11.0498 13.1746V13.9184C-11.0498 15.1502 -10.0504 16.1496 -8.81855 16.1496H10.5189C11.7508 16.1496 12.7502 15.1502 12.7502 13.9184V13.1746C12.7502 9.88816 10.0866 7.22461 6.80019 7.22461Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip15_641_10620)">
            <path
              d="M0.850195 56.7371C4.5457 56.7371 7.54395 53.7389 7.54395 50.0434C7.54395 46.3479 4.5457 43.3496 0.850195 43.3496C-2.84531 43.3496 -5.84355 46.3479 -5.84355 50.0434C-5.84355 53.7389 -2.84531 56.7371 0.850195 56.7371ZM6.80019 58.2246H4.23891C3.20695 58.6987 2.05879 58.9684 0.850195 58.9684C-0.358398 58.9684 -1.50191 58.6987 -2.53852 58.2246H-5.0998C-8.38625 58.2246 -11.0498 60.8882 -11.0498 64.1746V64.9184C-11.0498 66.1502 -10.0504 67.1496 -8.81855 67.1496H10.5189C11.7508 67.1496 12.7502 66.1502 12.7502 64.9184V64.1746C12.7502 60.8882 10.0866 58.2246 6.80019 58.2246Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip16_641_10620)">
            <path
              d="M83.3004 56.7371C86.9959 56.7371 89.9941 53.7389 89.9941 50.0434C89.9941 46.3479 86.9959 43.3496 83.3004 43.3496C79.6049 43.3496 76.6066 46.3479 76.6066 50.0434C76.6066 53.7389 79.6049 56.7371 83.3004 56.7371ZM89.2504 58.2246H86.6891C85.6571 58.6987 84.509 58.9684 83.3004 58.9684C82.0918 58.9684 80.9483 58.6987 79.9117 58.2246H77.3504C74.0639 58.2246 71.4004 60.8882 71.4004 64.1746V64.9184C71.4004 66.1502 72.3998 67.1496 73.6316 67.1496H92.9691C94.201 67.1496 95.2004 66.1502 95.2004 64.9184V64.1746C95.2004 60.8882 92.5368 58.2246 89.2504 58.2246Z"
              fill={fill}
            />
          </g>
          <g opacity="0.9" clipPath="url(#clip17_641_10620)">
            <path
              d="M83.3004 5.73711C86.9959 5.73711 89.9941 2.73887 89.9941 -0.956641C89.9941 -4.65215 86.9959 -7.65039 83.3004 -7.65039C79.6049 -7.65039 76.6066 -4.65215 76.6066 -0.956641C76.6066 2.73887 79.6049 5.73711 83.3004 5.73711ZM89.2504 7.22461H86.6891C85.6571 7.69875 84.509 7.96836 83.3004 7.96836C82.0918 7.96836 80.9483 7.69875 79.9117 7.22461H77.3504C74.0639 7.22461 71.4004 9.88816 71.4004 13.1746V13.9184C71.4004 15.1502 72.3998 16.1496 73.6316 16.1496H92.9691C94.201 16.1496 95.2004 15.1502 95.2004 13.9184V13.1746C95.2004 9.88816 92.5368 7.22461 89.2504 7.22461Z"
              fill={fill}
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(51 34)"
          />
        </clipPath>
        <clipPath id="clip1_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(9.34961 8.5)"
          />
        </clipPath>
        <clipPath id="clip2_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(9.34961 59.5)"
          />
        </clipPath>
        <clipPath id="clip3_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(10.2002 34)"
          />
        </clipPath>
        <clipPath id="clip4_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(51 59.5)"
          />
        </clipPath>
        <clipPath id="clip5_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(51 8.5)"
          />
        </clipPath>
        <clipPath id="clip6_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(30.5996 43.3496)"
          />
        </clipPath>
        <clipPath id="clip7_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(-11.0498 17.8496)"
          />
        </clipPath>
        <clipPath id="clip8_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(-11.0498 68.8496)"
          />
        </clipPath>
        <clipPath id="clip9_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(71.4004 68.8496)"
          />
        </clipPath>
        <clipPath id="clip10_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(30.5996 68.8496)"
          />
        </clipPath>
        <clipPath id="clip11_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(30.5996 -7.65039)"
          />
        </clipPath>
        <clipPath id="clip12_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(71.4004 17.8496)"
          />
        </clipPath>
        <clipPath id="clip13_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(30.5996 17.8496)"
          />
        </clipPath>
        <clipPath id="clip14_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(-11.0498 -7.65039)"
          />
        </clipPath>
        <clipPath id="clip15_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(-11.0498 43.3496)"
          />
        </clipPath>
        <clipPath id="clip16_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(71.4004 43.3496)"
          />
        </clipPath>
        <clipPath id="clip17_641_10620">
          <rect
            width="23.8"
            height="23.8"
            fill="white"
            transform="translate(71.4004 -7.65039)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

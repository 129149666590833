import type { ChangeEvent, RefObject } from 'react';
import type { EType } from 'src/types/user';
import SearchIcon from '../../assets/SearchIcon';

interface SearchComponentProps {
  placeholder: string;
  disabled?: boolean;
  width?: number;
  height?: number;
  value: string;
  onClick?: (e: EType) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClickCross?: () => void;
  inputClassName: string;
  showDeleteText?: boolean;
  widthFull?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  showSearchIconLeft?: boolean;
}

export const SearchComponent = ({
  placeholder,
  disabled = false,
  width,
  height,
  value,
  onClick,
  onChange,
  onClickCross,
  inputClassName,
  showDeleteText = true,
  widthFull = false,
  inputRef = undefined,
  showSearchIconLeft = true,
}: SearchComponentProps) => {
  const onClickCrossIcon = () => {
    if (onClickCross) {
      onClickCross();
    }
  };

  const onClickInput = (e: EType) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <div className={`flex relative items-center ${widthFull ? 'w-full' : ''}`}>
      {showSearchIconLeft && (
        <SearchIcon
          width={20}
          height={20}
          fill="#BCBCBC"
          className="absolute left-[7px]"
        />
      )}
      <input
        disabled={disabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onClick={onClickInput}
        onChange={onChange}
        value={value}
        type="text"
        alt="Search Input"
        className={`${inputClassName} rounded-[5px] border-[1px] border-[#949494] shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.2)] focus:shadow-[0px_0px_0px_2px_rgba(154,220,225,0.7)] transition w-[${width}px] h-[${height}px] disabled:opacity-[0.3] account-input`}
        placeholder={placeholder}
        ref={inputRef}
      />
      {value?.length > 0 && showDeleteText && (
        <div className="absolute right-[7px]">
          <svg
            width="20"
            height="22"
            viewBox="0 0 20 22"
            className="cursor-pointer"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClickCrossIcon}
          >
            <mask id="path-1-inside-1_2721_14369" fill="white">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 12.4866L15.2601 18.2726L16.7399 16.9273L11.3515 11L16.7399 5.07264L15.2601 3.72729L10 9.51336L4.73994 3.72729L3.26006 5.07264L8.64853 11L3.26006 16.9273L4.73994 18.2726L10 12.4866Z"
              />
            </mask>
            <path
              d="M15.2601 18.2726L13.7802 19.618L15.1255 21.0979L16.6054 19.7525L15.2601 18.2726ZM10 12.4866L11.4799 11.1412L10 9.51336L8.52012 11.1412L10 12.4866ZM16.7399 16.9273L18.0853 18.4072L19.5652 17.0618L18.2198 15.582L16.7399 16.9273ZM11.3515 11L9.87158 9.65462L8.64853 11L9.87158 12.3453L11.3515 11ZM16.7399 5.07264L18.2198 6.41799L19.5652 4.93811L18.0853 3.59276L16.7399 5.07264ZM15.2601 3.72729L16.6054 2.24742L15.1255 0.902069L13.7802 2.38195L15.2601 3.72729ZM10 9.51336L8.52012 10.8587L10 12.4866L11.4799 10.8587L10 9.51336ZM4.73994 3.72729L6.21982 2.38195L4.87447 0.902069L3.39459 2.24741L4.73994 3.72729ZM3.26006 5.07264L1.91471 3.59276L0.43483 4.93811L1.78018 6.41799L3.26006 5.07264ZM8.64853 11L10.1284 12.3453L11.3515 11L10.1284 9.65462L8.64853 11ZM3.26006 16.9273L1.78018 15.582L0.43483 17.0618L1.91471 18.4072L3.26006 16.9273ZM4.73994 18.2726L3.39459 19.7525L4.87447 21.0979L6.21982 19.618L4.73994 18.2726ZM16.7399 16.9273L11.4799 11.1412L8.52012 13.8319L13.7802 19.618L16.7399 16.9273ZM15.3946 15.4474L13.9147 16.7928L16.6054 19.7525L18.0853 18.4072L15.3946 15.4474ZM9.87158 12.3453L15.2601 18.2726L18.2198 15.582L12.8313 9.65462L9.87158 12.3453ZM15.2601 3.72729L9.87158 9.65462L12.8313 12.3453L18.2198 6.41799L15.2601 3.72729ZM13.9147 5.20717L15.3946 6.55252L18.0853 3.59276L16.6054 2.24742L13.9147 5.20717ZM11.4799 10.8587L16.7399 5.07264L13.7802 2.38195L8.52012 8.16802L11.4799 10.8587ZM3.26006 5.07264L8.52012 10.8587L11.4799 8.16802L6.21982 2.38195L3.26006 5.07264ZM4.6054 6.55252L6.08528 5.20718L3.39459 2.24741L1.91471 3.59276L4.6054 6.55252ZM10.1284 9.65462L4.73994 3.72729L1.78018 6.41799L7.16865 12.3453L10.1284 9.65462ZM4.73994 18.2726L10.1284 12.3453L7.16865 9.65462L1.78018 15.582L4.73994 18.2726ZM6.08528 16.7928L4.6054 15.4474L1.91471 18.4072L3.39459 19.7525L6.08528 16.7928ZM8.52012 11.1412L3.26006 16.9273L6.21982 19.618L11.4799 13.8319L8.52012 11.1412Z"
              fill="black"
              mask="url(#path-1-inside-1_2721_14369)"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

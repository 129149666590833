import React from 'react';
import type { DataWorldChartTypes } from '../../types/types';

interface iProps {
  countryFromRedux?: DataWorldChartTypes;
  children: React.ReactNode;
}

const Container = (props: iProps) => {
  const { children } = props;

  return (
    <div
      className={`relative z-[9] flex h-[115px] flex-col px-[15px] pt-[10px] max-md:mt-[-10px] max-md:bg-[#F4F4F4] md:h-[360px] md:px-[30px] md:pt-[28px]`}
    >
      {children}
    </div>
  );
};

export default Container;

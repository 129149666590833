interface GradientBarProps {
  gradientBarWidth?: string | null;
  isMobileLayout?: boolean;
}

const GradientBar = ({
  gradientBarWidth,
  isMobileLayout,
}: GradientBarProps) => {
  return (
    <svg
      width={
        isMobileLayout
          ? gradientBarWidth
            ? (gradientBarWidth as string)
            : '250'
          : '350'
      }
      height="14"
      viewBox={`0 0 ${
        isMobileLayout
          ? gradientBarWidth
            ? (gradientBarWidth as string)
            : '250'
          : '350'
      } 14`}
      fill="none"
    >
      <rect
        width={
          isMobileLayout
            ? gradientBarWidth
              ? (gradientBarWidth as string)
              : '250'
            : '350'
        }
        height="14"
        fill="url(#paint0_linear_269_12019)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_269_12019"
          x1="350"
          y1="7.00044"
          x2="3.92298e-08"
          y2="7.00044"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6B6B6B" />
          <stop offset="1" stopColor="#EDEDED" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GradientBar;

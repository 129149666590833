import type { ReactNode } from 'react';
import React, { useMemo } from 'react';

export enum Platform {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

interface RenderLayoutProps {
  platforms: Platform[];
  children: ReactNode;
}

/**
 * This is a helper function to avoid Layout shifts while the code determines what platform we're currently on
 * mobile ends at 768px (mobile breakpoint)
 * tablet ends at 1080px (tablet breakpoint)
 * explanation for other breakpoints but are not used here
 * smallMobile ends at 375px (smallMobile breakpoint)
 * bigTablet ends at 1180px (bigTablet breakpoint) - or small desktop
 * desktop ends at 1280px (desktop breakpoint)
 * bigDesktop starts at 1281px (never ends)
 * @param props
 * @returns
 */
const RenderLayout = ({ platforms, children }: RenderLayoutProps) => {
  const className = useMemo(() => {
    if (
      platforms.includes(Platform.MOBILE) &&
      platforms.includes(Platform.TABLET) &&
      platforms.includes(Platform.DESKTOP)
    ) {
      return 'render-layout-all';
    }

    if (
      platforms.includes(Platform.MOBILE) &&
      platforms.includes(Platform.TABLET)
    ) {
      return 'tablet:hidden render-layout-mob-tab';
    }

    if (
      platforms.includes(Platform.MOBILE) &&
      platforms.includes(Platform.DESKTOP)
    ) {
      return 'mobile:hidden tablet:block render-layout-mob-des';
    }

    if (
      platforms.includes(Platform.TABLET) &&
      platforms.includes(Platform.DESKTOP)
    ) {
      return 'hidden mobile:block render-layout-tab-des';
    }

    if (platforms.includes(Platform.DESKTOP)) {
      return 'hidden tablet:block render-layout-des';
    }

    if (platforms.includes(Platform.TABLET)) {
      return 'hidden mobile:block tablet:hidden render-layout-tab';
    }

    if (platforms.includes(Platform.MOBILE)) {
      return 'mobile:hidden render-layout-mob';
    }

    return 'render-layout-na';
  }, [platforms]);

  return <div className={className}>{children}</div>;
};

export default RenderLayout;

import { useSelector } from 'react-redux';
import { useRecordedPopulation } from '../../hooks/useRecordedPopulation';
import type { RootState } from '../../store';
import type { WorldDataTypes } from '../../types/world';

type RecordedPopulationProps = {
  WORdata?: WorldDataTypes;
};

export default function RecordedPopulation({
  WORdata,
}: Readonly<RecordedPopulationProps>) {
  const { country } = useSelector((state: RootState) => state.search);
  const { countryData } = useSelector((state: RootState) => state.country);

  const recordedPopulation = useRecordedPopulation({
    selectedCountry: country,
    lastRun: WORdata?.last_run || WORdata?.statistics?.stats_run.last_run,
    worldLivingPopulation: WORdata?.living,
    worldPendingPopulation: WORdata?.pending,
    countryData: countryData,
    loadingText: WORdata ? 'Loading...' : ' ',
  });

  return <span className="tabular-nums">{recordedPopulation}</span>;
}

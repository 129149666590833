export const continents = [
  {
    continent: 'Asia',
    region: '142',
  },
  {
    continent: 'Europe',
    region: '150',
  },
  {
    continent: 'Africa',
    region: '002',
  },
  {
    continent: 'Americas',
    region: '019',
  },
  {
    continent: 'Oceania',
    region: '009',
  },
];

export const regions = [
  {
    subcontinent: 'North America',
    region: '021',
  },
  {
    subcontinent: 'Central America',
    region: '013',
  },
  {
    subcontinent: 'South America',
    region: '005',
  },
  {
    subcontinent: 'Oceania',
    region: '009',
  },
  {
    subcontinent: 'Western Europe',
    region: '155',
  },
  {
    subcontinent: 'Northern Europe',
    region: '154',
  },
  {
    subcontinent: 'Eastern Europe',
    region: '151',
  },
  {
    subcontinent: 'Southern Europe',
    region: '039',
  },
  {
    subcontinent: 'West Africa',
    region: '011',
  },
  {
    subcontinent: 'West Asia',
    region: '145',
  },
  {
    subcontinent: 'Southern Asia',
    region: '034',
  },
  {
    subcontinent: 'South-eastern Asia',
    region: '035',
  },
  {
    subcontinent: 'Eastern Asia',
    region: '030',
  },
  {
    subcontinent: 'Central Asia',
    region: '143',
  },
];

import { animated, useSpring } from '@react-spring/web';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

interface FirstDrawerProps {
  handleCloseFirstTimeOnTpp: () => void;
  firstTimeOnTppState: boolean;
  desktopRender?: boolean;
}

const FirstDrawer = ({
  handleCloseFirstTimeOnTpp,
  firstTimeOnTppState,
  desktopRender = false,
}: FirstDrawerProps) => {
  const stylesForFirstTimeOnTpp = useSpring({
    left: firstTimeOnTppState ? '0px' : '-430px',
    config: { tension: 600, friction: 50 },
  });

  const { t } = useTranslation('new-homepage');

  return (
    <animated.div
      key={firstTimeOnTppState ? 'visible' : 'hidden'}
      className={`flex absolute left-[50%] z-[49] flex-col m-0 w-[87vw] bg-white border-2 border-black shadow-2xl ${
        !desktopRender ? 'translate-x-[-50%]' : ''
      } origin-center tablet:mt-[-110px] tablet:w-[420px] rounded-lg tablet:rounded-l-none`}
      style={desktopRender ? stylesForFirstTimeOnTpp : {}}
    >
      <div className="flex flex-col p-4 w-full h-full tablet:p-5">
        <div className="flex items-center py-5">
          <p className="text-xl font-bold tracking-widest align-bottom tablet:text-2xl">
            WELCOME!
          </p>
          <div className="flex flex-1 justify-end">
            <button onClick={handleCloseFirstTimeOnTpp} title="Close Drawer">
              <svg
                width="42"
                height="42"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </div>
        </div>
        <div className="py-2">
          <p className="text-sm tablet:text-base">
            {t('the-population-project-is-the-first-attempt', {
              ns: 'new-homepage',
            })}
          </p>
        </div>
        <div className="py-2">
          <p className="text-sm tablet:text-base">
            {t('since-countries-dont-public-their-census', {
              ns: 'new-homepage',
            })}{' '}
            <br />{' '}
            {t('were-a-nonprofit-with-no-income-or-clients', {
              ns: 'new-homepage',
            })}
          </p>
        </div>
        <div className="py-2">
          <p className="text-sm font-bold text-[#409F85] tablet:text-base">
            {t('click-on-any-country', { ns: 'new-homepage' })}
          </p>
        </div>
        <div className="flex mt-[150px] mb-auto w-full">
          <Link
            href="/world"
            className={`flex cursor-pointer justify-center mt-2 disabled:opacity-[0.3] active:shadow-[0px_0px_0px_4px_rgba(154,220,225,0.7)] hover:shadow-lg hover:bg-[#2F9277] transition items-center rounded-1 bg-[#409F85] w-full px-10 py-3`}
            title="See More World Data"
            aria-label="See More World Data"
          >
            <p className="font-[700] text-sm tracking-[0.04em] leading-5 text-center text-white tablet:text-base">
              See World Statistics
            </p>
          </Link>
        </div>
      </div>
    </animated.div>
  );
};

export default FirstDrawer;

interface LabelProps {
  children: string;
  className?: string;
  fontSize?: number;
  textColor?: string;
  lineHeight?: number;
  fontWeight?: number;
}

export const Label = ({
  children,
  className,
  fontSize = 14,
  textColor = '#A8A8A8',
  lineHeight = 17,
  fontWeight = 500,
}: LabelProps) => {
  return (
    <p
      className={`font-[${fontWeight}] text-[${textColor}] text-[${fontSize}px] leading-[${lineHeight}px] ${className}`}
    >
      {children}
    </p>
  );
};

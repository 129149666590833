import type { TFunction } from 'next-i18next';

interface ThreeTabToggleHorizontalProps {
  isCompletion: boolean;
  isCertainPercentage: boolean;
  byIsCertainPercentageOnClick: () => void;
  byPercOnClick: () => void;
  byCompletionIndexOnClick: () => void;
  isMobileLayout: boolean;
  isRecorded: boolean;
  t: TFunction;
  disabled?: boolean;
}

export const ThreeTabToggleHorizontal = ({
  isCompletion,
  isCertainPercentage,
  byIsCertainPercentageOnClick,
  byPercOnClick,
  byCompletionIndexOnClick,
  isMobileLayout,
  isRecorded,
  disabled = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  t,
}: ThreeTabToggleHorizontalProps) => {
  const isCompletionOnly = isCompletion && !isRecorded && !isCertainPercentage;
  const isRecordedOnly = isRecorded && !isCompletion && !isCertainPercentage;
  const isCertainOnly = isCertainPercentage && !isRecorded && !isCompletion;
  return (
    <div
      aria-disabled={disabled}
      className="flex justify-center py-[3.14px] px-[3px] my-[25px] bg-[#ECECEC] rounded-[5px]"
    >
      <div className="flex items-center">
        <button
          onClick={byCompletionIndexOnClick}
          className={`${
            isMobileLayout ? 'w-[110.5px]' : 'w-[120px]'
          } h-[35px] flex justify-center items-center ${
            isCompletionOnly ? 'bg-[#FFFFFF]' : 'bg-[#ECECEC]'
          } rounded-[5px] cursor-pointer focus:shadow-[0px_0px_0px_4px_rgba(154,220,225,0.7)] ${
            isCompletionOnly ? '' : 'hover:bg-[#FFFFFF95]'
          } transition realtive z-[${isCompletionOnly ? 2 : 1}]`}
        >
          <p
            className={`flex items-center font-normal ${
              isCompletionOnly ? 'text-black' : 'text-black opacity-[0.6]'
            } ${isMobileLayout ? 'text-[14px] px-[10px]' : 'text-[16px]'}`}
          >
            Complete
          </p>
        </button>
      </div>
      <div className="flex items-center border-[#C4C4C4]">
        {isRecorded ? null : !isRecorded &&
          isCompletion &&
          !isCertainPercentage ? null : (
          <div className="w-[1px] h-[31.42px] bg-[#000000] opacity-[0.2]" />
        )}
        <button
          onClick={byPercOnClick}
          className={`${
            isMobileLayout ? 'w-[110.5px]' : 'w-[120px]'
          } h-[35px] flex justify-center items-center ${
            isRecordedOnly ? 'bg-[#FFFFFF]' : 'bg-[#ECECEC]'
          } rounded-[5px] cursor-pointer focus:shadow-[0px_0px_0px_4px_rgba(154,220,225,0.7)] ${
            isRecordedOnly ? '' : 'hover:bg-[#FFFFFF95]'
          } transition realtive z-[${isRecordedOnly ? 2 : 1}]`}
        >
          <p
            className={`font-normal ${
              isRecordedOnly ? 'text-black' : 'text-black opacity-[0.6]'
            } ${isMobileLayout ? 'text-[14px] px-[5px]' : 'text-[16px]'}`}
          >
            Recorded
          </p>
        </button>
        {isRecorded ? null : !isRecorded &&
          !isCompletion &&
          isCertainPercentage ? null : (
          <div className="w-[1px] h-[31.42px] bg-[#000000] opacity-[0.2]" />
        )}
      </div>
      <div className="flex items-center">
        <button
          onClick={byIsCertainPercentageOnClick}
          className={`${
            isMobileLayout ? 'w-[110.5px]' : 'w-[120px]'
          } h-[35px] flex justify-center items-center ${
            isCertainOnly ? 'bg-[#FFFFFF]' : 'bg-[#ECECEC]'
          } rounded-[5px] cursor-pointer focus:shadow-[0px_0px_0px_4px_rgba(154,220,225,0.7)] ${
            isCertainOnly ? '' : 'hover:bg-[#FFFFFF95]'
          } transition realtive z-[1]`}
        >
          <p
            className={`flex items-center font-normal ${
              isCertainOnly ? 'text-black' : 'text-black opacity-[0.6]'
            } ${isMobileLayout ? 'text-[14px] px-[10px]' : 'text-[16px]'}`}
          >
            Certain
          </p>
        </button>
      </div>
    </div>
  );
};

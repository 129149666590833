import cx from 'clsx';
import { Logo } from 'src/components/brand/logo.component';
import { Type } from 'src/components/core';

import { PageContainer } from './pageContainer.component';

export interface PageTitleBannerProps {
  className?: string;
  title: string;
  subtitle?: string;
}

export function PageTitleBanner({
  className,
  title,
  subtitle,
}: PageTitleBannerProps) {
  return (
    <div
      className={cx(
        'bg-mosaic bg-contain shadow-overlay pt-10 pb-6 sm:pt-14 sm:pb-10',
        className
      )}
    >
      <PageContainer className="flex justify-between">
        <div>
          <Type
            component="h1"
            color="text-white"
            variant={['text-4xl', 'sm:text-5xl']}
            weight="font-bold"
          >
            {title}
          </Type>
          {subtitle !== undefined && (
            <Type
              component="h3"
              color="text-gray-400"
              className="mt-2 sm:mt-3"
              variant={['text-base', 'sm:text-lg']}
            >
              {subtitle}
            </Type>
          )}
        </div>
        <Logo
          contrast="inverse"
          type="stacked"
          size="large"
          className="shrink-0 ml-1"
        />
      </PageContainer>
    </div>
  );
}

import cx from 'clsx';
import Head from 'next/head';
import type { ReactNode } from 'react';
import { NavFooter } from 'src/components/nav/navFooter.component';
import type { DataWorldChartTypes } from 'src/types/types';
import { cn } from '../../utils/cn';
import type { PageContainerProps } from './pageContainer.component';
import { PageContainer } from './pageContainer.component';
import { PageTitleBanner } from './pageTitleBanner.component';
import StandardSEOWrapper from './standardSEOWrapper.component';

export interface PageLayoutProps extends PageContainerProps {
  countries?: DataWorldChartTypes[];
  bgColor?: string;
  children: ReactNode;
  className?: string;
  containerClassName?: string;
  hasTitleBanner?: boolean;
  description?: string;
  title: string;
  bannerTitle?: string;
  secondaryNav?: ReactNode;
  subtitle?: string;
}

export function PageLayout({
  countries,
  bgColor = 'bg-white',
  children,
  className,
  description,
  containerClassName,
  hasTitleBanner = false,
  title,
  bannerTitle = title,
  secondaryNav = null,
  subtitle,
  ...containerProps
}: Readonly<PageLayoutProps>) {
  return (
    <div className={cx(`min-h-screen max-md:pb-[120px]`, bgColor, className)}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=1, minimum-scale=1, maximum-scale=5"
        />
      </Head>
      <StandardSEOWrapper title={title} description={description} />
      {secondaryNav}
      {hasTitleBanner && !!bannerTitle && (
        <PageTitleBanner title={bannerTitle} subtitle={subtitle} />
      )}
      <PageContainer
        className={cn(
          `min-h-full md:min-h-[60vh] pt-[10px]`,
          containerClassName
        )}
        {...containerProps}
      >
        {children}
      </PageContainer>

      <NavFooter className="mt-2" />
    </div>
  );
}

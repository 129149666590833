import { worldDomainName } from 'src/config';
import { calls } from 'src/utils/calls';
import { axiosInstance } from 'src/utils/instances';

export const getCountry = async (countryIso3: string, domainName: string) => {
  return axiosInstance(calls.getCountry(countryIso3, domainName))
    .then((res) => {
      if (res) {
        return res.data?.data;
      }
    })
    .catch((err) => console.log(err));
};

export const getCountryStats = (countryIso3: string, domainName: string) => {
  return axiosInstance(calls.getCountryStats(countryIso3, domainName))
    .then((res) => {
      if (res) {
        return res.data;
      }
    })
    .catch((err) => console.log(err));
};

export const getCountryTopNames = (
  countryIso3?: string,
  domainName?: string,
  page = '1',
  pageSize = '10'
) => {
  return axiosInstance(
    calls.getTopNamesData(countryIso3, domainName, page, pageSize)
  )
    .then((res) => {
      if (res) {
        return res.data;
      }
    })
    .catch((err) => console.log(err));
};
export const getCountryTopNamesStaticProps = (
  countryIso3?: string,
  domainName?: string,
  page = '1',
  pageSize = '10'
) => {
  return axiosInstance(
    calls.getTopNamesDataStaticProps(countryIso3, domainName, page, pageSize)
  )
    .then((res) => {
      if (res) {
        return res.data;
      }
    })
    .catch((err) => console.log(err));
};

export const getCountryStaticProps = async (
  countryIso3: string,
  domainName: string
) => {
  try {
    const res = await axiosInstance(
      calls.getCountryStaticProps(countryIso3, domainName)
    );
    return res.data?.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllCountriesWithRegionOrState = () => {
  return axiosInstance(calls.getAllCountriesWithRegionOrState())
    .then((res) => {
      const countries = res?.data;
      countries?.forEach((country: { place: string }) => {
        if (country?.place === 'UNITED STATES OF AMERICA') {
          return (country.place = 'United States of America');
        }
      });

      return countries;
    })
    .catch((err) => {
      throw err;
    });
};

export const getAllCountriesWithRegionOrStateStaticProps = () => {
  return axiosInstance(calls.getAllCountriesWithRegionOrStateStaticProps())
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

export const getLocation = (countryIso3: string, domainName: string) => {
  return axiosInstance(
    calls.getLocation(countryIso3, domainName || worldDomainName)
  )
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getLocationPath = (
  countryIso3: string,
  path: string,
  domainName: string
) => {
  return axiosInstance(
    calls.getLocationPath(countryIso3, path, domainName || worldDomainName)
  )
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      throw err;
    });
};

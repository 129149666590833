import type { IconProps } from '../types/icons';

export default function UsersIcon({
  width = 92,
  height = 70,
  fill = 'none',
  className = '',
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={className}
    >
      <g transform={`scale(${width / 92},${height / 70})`}>
        <g opacity="0.9" clipPath="url(#clip0_641_10609)">
          <path
            d="M74.9649 41.9393C80.2121 41.9393 84.4692 37.6821 84.4692 32.435C84.4692 27.1878 80.2121 22.9307 74.9649 22.9307C69.7177 22.9307 65.4606 27.1878 65.4606 32.435C65.4606 37.6821 69.7177 41.9393 74.9649 41.9393ZM83.4132 44.0514H79.7765C78.3112 44.7246 76.681 45.1074 74.9649 45.1074C73.2489 45.1074 71.6252 44.7246 70.1534 44.0514H66.5166C61.8503 44.0514 58.0684 47.8333 58.0684 52.4996V53.5557C58.0684 55.3047 59.4874 56.7238 61.2365 56.7238H88.6934C90.4424 56.7238 91.8615 55.3047 91.8615 53.5557V52.4996C91.8615 47.8333 88.0795 44.0514 83.4132 44.0514Z"
            fill={fill}
          />
        </g>
        <g opacity="0.9" clipPath="url(#clip1_641_10609)">
          <path
            d="M17.0342 41.9393C22.2814 41.9393 26.5386 37.6821 26.5386 32.435C26.5386 27.1878 22.2814 22.9307 17.0342 22.9307C11.7871 22.9307 7.52994 27.1878 7.52994 32.435C7.52994 37.6821 11.7871 41.9393 17.0342 41.9393ZM25.4825 44.0514H21.8458C20.3806 44.7246 18.7503 45.1074 17.0342 45.1074C15.3182 45.1074 13.6945 44.7246 12.2227 44.0514H8.58597C3.91962 44.0514 0.137695 47.8333 0.137695 52.4996V53.5557C0.137695 55.3047 1.55674 56.7238 3.3058 56.7238H30.7627C32.5118 56.7238 33.9308 55.3047 33.9308 53.5557V52.4996C33.9308 47.8333 30.1489 44.0514 25.4825 44.0514Z"
            fill={fill}
          />
        </g>
        <g opacity="0.9" clipPath="url(#clip2_641_10609)">
          <path
            d="M46.0001 55.2157C51.2472 55.2157 55.5044 50.9585 55.5044 45.7113C55.5044 40.4642 51.2472 36.207 46.0001 36.207C40.7529 36.207 36.4958 40.4642 36.4958 45.7113C36.4958 50.9585 40.7529 55.2157 46.0001 55.2157ZM54.4483 57.3277H50.8116C49.3464 58.0009 47.7161 58.3838 46.0001 58.3838C44.284 58.3838 42.6604 58.0009 41.1885 57.3277H37.5518C32.8854 57.3277 29.1035 61.1096 29.1035 65.776V66.832C29.1035 68.5811 30.5226 70.0001 32.2716 70.0001H59.7285C61.4776 70.0001 62.8966 68.5811 62.8966 66.832V65.776C62.8966 61.1096 59.1147 57.3277 54.4483 57.3277Z"
            fill={fill}
          />
        </g>
        <g opacity="0.9" clipPath="url(#clip3_641_10609)">
          <path
            d="M46.0001 19.0086C51.2472 19.0086 55.5044 14.7515 55.5044 9.50431C55.5044 4.25714 51.2472 0 46.0001 0C40.7529 0 36.4958 4.25714 36.4958 9.50431C36.4958 14.7515 40.7529 19.0086 46.0001 19.0086ZM54.4483 21.1207H50.8116C49.3464 21.7939 47.7161 22.1767 46.0001 22.1767C44.284 22.1767 42.6604 21.7939 41.1885 21.1207H37.5518C32.8854 21.1207 29.1035 24.9026 29.1035 29.569V30.625C29.1035 32.3741 30.5226 33.7931 32.2716 33.7931H59.7285C61.4776 33.7931 62.8966 32.3741 62.8966 30.625V29.569C62.8966 24.9026 59.1147 21.1207 54.4483 21.1207Z"
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_641_10609">
          <rect
            width="33.7931"
            height="33.7931"
            fill="white"
            transform="translate(58.0684 22.9307)"
          />
        </clipPath>
        <clipPath id="clip1_641_10609">
          <rect
            width="33.7931"
            height="33.7931"
            fill="white"
            transform="translate(0.137695 22.9307)"
          />
        </clipPath>
        <clipPath id="clip2_641_10609">
          <rect
            width="33.7931"
            height="33.7931"
            fill="white"
            transform="translate(29.1035 36.207)"
          />
        </clipPath>
        <clipPath id="clip3_641_10609">
          <rect
            width="33.7931"
            height="33.7931"
            fill="white"
            transform="translate(29.1035)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

import dynamic from 'next/dynamic';
import React from 'react';
import type { ReactGoogleChartProps } from 'react-google-charts';

interface GoogleWorldMapChartProps {
  chartEventsVersion?: number;
  worldChartWidth: number;
  isMobileLayout?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  height?: number | string;
  namePage?: boolean;
  chartEvents?: ReactGoogleChartProps['chartEvents'];
  gcoptions?: ReactGoogleChartProps['options'];
}

const LazyGoogleChart = dynamic(() => import('react-google-charts'), {
  loading: () => <div></div>,
  ssr: false,
});

const GoogleWorldMapChart = ({
  worldChartWidth,
  isMobileLayout,
  data,
  height,
  namePage,
  chartEvents,
  chartEventsVersion,
  gcoptions,
}: GoogleWorldMapChartProps) => {
  return (
    <div>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <LazyGoogleChart
        graphID={'geoChart'}
        chartEvents={chartEvents as ReactGoogleChartProps['chartEvents']}
        chartType="GeoChart"
        width={worldChartWidth as number}
        height={namePage ? height : isMobileLayout ? 400 : 680}
        data={data}
        options={gcoptions}
      />
    </div>
  );
};

GoogleWorldMapChart.displayName = 'GoogleWorldMapChart';

export default React.memo(GoogleWorldMapChart);

import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from '../store';
import type { DataWorldChartTypes } from '../types/types';
import { formatNumber } from '../utils/format';
import getPassedSeconds from '../utils/getPassedSeconds';

type IPopulationCounter = {
  selectedCountry?: DataWorldChartTypes;
  worldLivingPopulation?: number;
  worldPendingPopulation?: number;
  countryData?: {
    population: { living: number; pending: number };
    statistics: { stats_run: { last_run: string } };
  };
  lastRun?: string;
  loadingText?: string;
};

export const useRecordedPopulation = (props: IPopulationCounter): string => {
  const {
    selectedCountry,
    worldLivingPopulation = 0,
    countryData,
    worldPendingPopulation = 0,
    lastRun,
    loadingText = ' ',
  } = props;
  const [count, setCount] = useState<number>(0);
  const { isLoadingCountryData } = useSelector(
    (state: RootState) => state.country
  );

  const displayNumberOfHumans = useMemo(() => {
    if (count && isEmpty(selectedCountry)) {
      return `${formatNumber(Math.round(count))}`;
    }

    if (!isEmpty(selectedCountry)) {
      if (isLoadingCountryData && !countryData) {
        return loadingText;
      }
      if (isNaN(count)) {
        return ' ';
      }
      return count
        ? `${formatNumber(count)}`
        : selectedCountry?.living
        ? loadingText
        : ' ';
    } else if (worldLivingPopulation == 0) {
      return loadingText;
    } else {
      return ' ';
    }
  }, [count, selectedCountry, isLoadingCountryData]);

  useEffect(() => {
    if (isEmpty(selectedCountry)) {
      const incrementValue =
        worldPendingPopulation == 0 || !worldPendingPopulation
          ? 0.2
          : worldPendingPopulation / 86400;

      const intervalTime = incrementValue >= 10 ? 100 : 1000 / incrementValue;

      lastRun &&
        setCount(
          Math.round(
            worldLivingPopulation +
              getPassedSeconds(lastRun) *
                (incrementValue > 10 ? 10 : incrementValue)
          )
        );

      const addHumansInterval = setInterval(() => {
        setCount((prevState) => prevState + 1);
      }, intervalTime);

      return () => clearInterval(addHumansInterval);
    } else if (countryData?.population?.living) {
      const incrementValue =
        countryData?.population?.pending == 0
          ? 0.2
          : countryData?.population?.pending / 86400;

      const intervalTime = incrementValue >= 10 ? 100 : 1000 / incrementValue;

      setCount(
        Math.round(
          countryData?.population?.living +
            // eslint-disable-next-line prettier/prettier
            getPassedSeconds(countryData?.statistics?.stats_run?.last_run) *
              (incrementValue > 10 ? 10 : incrementValue)
        )
      );

      const countryHumanInterval = setInterval(() => {
        setCount((prevState) => prevState + 1);
      }, intervalTime);

      return () => clearInterval(countryHumanInterval);
    } else {
      setCount(0);
    }
  }, [
    selectedCountry?.population,
    countryData?.population?.living,
    lastRun,
    worldLivingPopulation,
  ]);

  return displayNumberOfHumans;
};

import type { IconProps } from '../types/icons';

export default function UserIcon({
  width = 61,
  height = 60,
  fill = 'none',
  className = '',
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={className}
    >
      <path
        d="M30.5 33.75C39.8164 33.75 47.375 26.1914 47.375 16.875C47.375 7.55859 39.8164 0 30.5 0C21.1836 0 13.625 7.55859 13.625 16.875C13.625 26.1914 21.1836 33.75 30.5 33.75ZM45.5 37.5H39.043C36.4414 38.6953 33.5469 39.375 30.5 39.375C27.4531 39.375 24.5703 38.6953 21.957 37.5H15.5C7.21484 37.5 0.5 44.2148 0.5 52.5V54.375C0.5 57.4805 3.01953 60 6.125 60H54.875C57.9805 60 60.5 57.4805 60.5 54.375V52.5C60.5 44.2148 53.7852 37.5 45.5 37.5Z"
        fill={fill}
        transform={`scale(${width / 61},${height / 60})`}
      />
    </svg>
  );
}
